import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersistence from 'vuex-persist'

import company from '@/store/modules/company'
import user from '@/store/modules/user'
import parametrosPainelValidacoes from '@/store/modules/parametrosPainelValidacoes'
import caixaDeEntrada from './store/modules/caixaDeEntrada'
import parametrosConsultaAprovacoesTemas from './store/modules/parametrosConsultaAprovacoesTemas'
import parametrosDashboardLno from './store/modules/parametrosDashboardLno'
import parametrosDashboardServicos from './store/modules/parametrosDashboardServicos'
import parametrosDashboardFaturamento from './store/modules/parametrosDashboardFaturamento'
import parametrosDashboardAnexo1 from './store/modules/parametrosDashboardAnexo1'
import parametrosDashboardNivelTensao from './store/modules/parametrosDashboardNivelTensao'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: [
    'company',
    'user',
    'parametrosPainelValidacoes',
    'parametrosConsultaAprovacoesTemas',
    'parametrosDashboardLno',
    'parametrosDashboardServicos',
    'parametrosDashboardFaturamento',
    'parametrosDashboardAnexo1',
    'parametrosDashboardNivelTensao',
    'caixaDeEntrada'
  ]
})

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    company,
    user,
    parametrosPainelValidacoes,
    caixaDeEntrada,
    parametrosConsultaAprovacoesTemas,
    parametrosDashboardLno,
    parametrosDashboardServicos,
    parametrosDashboardFaturamento,
    parametrosDashboardAnexo1,
    parametrosDashboardNivelTensao
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: true,
    watchBreadcrumb: false,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    WATCH_CHANGE_BREADCRUMB(state){
      state.watchBreadcrumb = !state.watchBreadcrumb
    },
  },
  actions: {

  },
  plugins: [vuexLocal.plugin]
})